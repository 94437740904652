import { Guid } from '@komo-tech/core/models/Guid';
import { mapArray } from '@komo-tech/core/utils/array';
import { asBoolean } from '@komo-tech/core/utils/boolean';
import { filterKeys } from '@komo-tech/core/utils/object';

import {
  FieldConfig,
  isIHasPlaceholder,
  isIHasWordLimit
} from '@/common/models/form/FormFieldConfig';
import { FieldTypes } from '@/common/models/form/FormFieldTypes';

import { ContactPropertyDefinitionProperties } from './ContactPropertyDefinitionProperties';
import { ContactPropertyTypes } from './ContactPropertyTypes';
import { ContactPropertyValueScopes } from './ContactPropertyValueScopes';
import { FormFieldContactPropertyOption } from './FormFieldContactPropertyOption';

/**
 * ViewModel of ContactPropertyDefinition for use in the front+admin facing FromField.
 * As there is only one Form model (no front/admin split) we will match it with this one model.
 */
export class FormFieldPropertyDefinition {
  id: Guid;
  groupId?: Guid;
  type: ContactPropertyTypes;
  fieldType?: FieldTypes;
  name: string;
  label: string;
  valueScope: ContactPropertyValueScopes;

  options: FormFieldContactPropertyOption[];

  properties: ContactPropertyDefinitionProperties;

  sessionFieldConfig: SessionFieldConfig;

  isSystemGenerated?: boolean;

  get description() {
    return this.properties.Description;
  }

  get placeholder() {
    if (isIHasPlaceholder(this.sessionFieldConfig.fieldConfig)) {
      return this.sessionFieldConfig.fieldConfig.Placeholder;
    }

    return '';
  }

  get wordLimit() {
    if (isIHasWordLimit(this.sessionFieldConfig.fieldConfig)) {
      return this.sessionFieldConfig.fieldConfig.WordLimit;
    }

    return undefined;
  }

  getTypedFieldConfig() {
    return this.sessionFieldConfig.fieldConfig;
  }

  constructor(props?: Partial<FormFieldPropertyDefinition>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.groupId = Guid.valueOrUndefined(props.groupId);
    this.options = mapArray(
      props.options,
      (x) => new FormFieldContactPropertyOption(x)
    );
    this.properties = new ContactPropertyDefinitionProperties(props.properties);
    this.isSystemGenerated = asBoolean(props.isSystemGenerated);

    // init the field config on construction so JSON parsing is only happening once.
    this.sessionFieldConfig = new SessionFieldConfig({
      fieldConfig: this.properties.getTypedFieldConfig(this.fieldType)
    });
  }

  // ignore session field config during JSON serialization
  public toJSON() {
    return filterKeys(this, (key) => key !== 'sessionFieldConfig');
  }
}

export class SessionFieldConfig {
  fieldConfig?: FieldConfig;

  constructor(props?: Partial<SessionFieldConfig>) {
    props = props || {};
    Object.assign(this, props);
  }
}
